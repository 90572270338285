import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CarrierSales } from './list';
import { CreateCarrierBidComponent } from '../carrier-bids/components/create-carrier-bid/create-carrier-bid.component';
import { DetailCarrierBidComponent } from '../carrier-bids/components/detail-carrier-bid/detail-carrier-bid.component';
import { DatLoadComponent } from '../carrier-bids/components/dat-load/dat-load.component';


const carrierSalesRoutes: Routes = [
  { path: '', component: CarrierSales, data: { title: 'Carrier Sales - WARP Admin' } },
  { path: 'create/:jobId', component: CreateCarrierBidComponent, data: { title: 'Carrier BID - WARP Admin' } },
  { path: 'edit/:id', component: CreateCarrierBidComponent, data: { title: 'Carrier BID - WARP Admin' } },
  { path: ':id/dat-load', component: DatLoadComponent, data: { title: 'Carrier BID - WARP Admin' } },
  { path: ':id', component: DetailCarrierBidComponent, data: { title: 'Carrier BID - WARP Admin' } },
];

@NgModule({
  imports: [RouterModule.forChild(carrierSalesRoutes)],
  exports: [RouterModule]
})
export class CarrierSalesRoutingModule { }
